import axios from 'axios'

export function getAllPais () {
  return axios.get('/maestros/pais')
  .then(response => {
    return response.data
  })
}

export function updatePais (id, pais) {
  return axios.put('/maestros/pais/' + id, pais)
  .then(response => {
      return response.data
    })
}

export function addPais (pais) {
  return axios.post('/maestros/pais', pais)
  .then(response => {
    return response.data
  })
}

export function deletePais (id) {
  return axios.delete('/maestros/pais/' + id)
  .then(response => {
      return response.data
    })
}

export function getOnePais (id) {
    return axios.get('/maestros/pais/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsPais (id) {
  return axios.get('/maestros/pais/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllPais,
  updatePais,
  addPais,
  deletePais,
  getOnePais,
  getLogsPais,
}
